@import "src/assets/styles/include-media";
@import "../../assets/styles/helpers";

@mixin adjust-spacing($width-threshold) {
  .header-icon_separator {
    @include media(">=#{$width-threshold}") {
      margin-left: 15px;
      margin-right: 15px;
    }
    @include media("<=#{$width-threshold}") {
      margin-left: 10px;
      margin-right: 10px;
    }
    height: 75%;
    //border-left: 1px solid #0000005d;
  }

  .separator-black {
    border-left: 2px solid #0000005d;
    height: 100%;
  }

  .console-btns {
    display: flex;
    justify-content: space-around;
    //width: 29.95rem;

    .console-btn {
      @include media(">=#{$width-threshold}") {
        margin-left: 1.2rem;
      }
      @include media("<#{$width-threshold}") {
        font-size: 12.5px;
      }
      &:first-child {
        @include media(">=#{$width-threshold}") {
          margin-left: 0;
        }
      }
      &:nth-child(3) {
        @include media(">=#{$width-threshold}") {
          width: 7rem;
        }
      }
    }

    .console-btn-img img {
      max-width: 30px;
      @include media(">=#{$width-threshold}") {
        width: 27px;
      }
      @include media("<#{$width-threshold}") {
        min-width: 20px;
      }
    }

    .console-btn-span {
      @include media("<#{$width-threshold}") {
        display: none;
      }
      margin-left: 0.5rem;
      font-size: smaller;
    }
  }
}

.header-skytap {
  @include adjust-spacing(1260px);
  .separator-black {
    @include media(">=1260px") {
      margin: 0 1.7rem;
    }
  }
  .lab-name {
    @include media(">=1366px") {
      max-width: 10rem;
    }
    max-width: 7rem;
  }
  .console-btns {
    @include media("<=1260px") {
      width: 18.96rem;
    }
  }
}

.header-vcd {
  @include adjust-spacing(1366px);
  .separator-black {
    @include media(">=1366px") {
      margin: 0 15px;
    }
  }
  .lab-name {
    @include media(">=1366px") {
      max-width: 9rem;
    }
    max-width: 6rem;
  }
  .console-btns {
    @include media("<1366px") {
      width: 11.96rem;
    }
  }
}

.header {
  // width: 100vw;
  // position: sticky;
  display: flex;
  justify-content: space-between;
  padding: 0 0.5rem;
  height: 3.8rem;
  &-user {
    background-color: #ffffff;
    color: black;
  }
  &-console {
    // border-bottom: 1px solid #3e3e3e;
    background-color: #222222;
    color: white;
  }
}

.logo {
  &-full {
    @include media("<=1420px") {
      display: none;
    }
  }
  &-half {
    @include media(">1420px") {
      display: none;
    }
  }
}

.header-icon {
  //width: 60%;
  margin-left: 0.5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  &_menu {
    cursor: pointer;
  }
  &_img {
    transform: scale(-1, 1);
  }
  &_logo {
    margin-left: 0.5rem;
  }
  &_user {
    color: var(--color-header-text);
    @include media(">=1420px") {
      font-size: 1rem;
    }
    @include media("<1420px") {
      font-size: 0.8rem;
    }
  }
}

.header-end {
  width: 100%;
  justify-self: flex-end;
  display: flex;
  justify-content: flex-end;
}

.header-middle {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-end;
  // width: 60%;
  font-size: 13px;
  align-items: flex-start;
  margin-right: 21px;
  &-time {
    display: flex;
    flex-flow: column wrap;
    // margin-top: 5px;
    &_text {
      font-size: small;
    }
    &_unit {
      font-size: 1rem;
      padding: 2px 5px;
      background-color: #1a1a1a;
      width: 1.8em;
      display: inline-block;
      border-radius: 2px;
      letter-spacing: 1px;
    }
  }
  &-btn {
    // margin-top: 1rem;
    display: flex;
    &_extend {
      // margin-top: 0.2rem;
      font-size: 1em;
      height: 2em;
      color: var(--color-primary);
      background-color: white;
    }
  }
}

.reorder-clock {
  order: 3;
}

.lab-name {
  min-width: 40px;
  letter-spacing: 0.7px;
}

.separator-white {
  border-left: 1px solid var(--color-secondary-text);
}

.header-right {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  position: relative;
  // width: 35%;
  font-size: 1em;
  &_notification {
    display: flex;
    justify-content: stretch;
    margin-top: 0.1rem;
    &-ring {
      position: relative;
    }
    &-count {
      font-size: 0.7rem;
      color: #ffffff;
      background-color: orange;
      border-radius: 50%;
      position: absolute;
      top: -0.4rem;
      right: -0.2rem;
      padding: 0 0.2rem;
    }
  }

  &_profile {
    margin-right: 1rem;
    // margin-left: 1rem;
    display: flex;
    align-items: center;
    position: relative;

    .dropdown {
      min-width: 30px;
    }
  }
}

.username {
  align-self: center;
  font-weight: 500;
  margin-left: 0.4rem;
}

.userprofile-image {
  @include avatar(25px);
}

.dropdown-item.active {
  background-color: white;
  color: var(--color-primary);
}

.console {
  &-btn {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .full-screen-span {
      min-width: 60px;
    }

    &-img {
      background-color: var(--color-header-icons);
      border-radius: 5px;
    }

    &-action {
      padding: 0 9px;
      height: 27px;
      display: flex;
      align-items: center;

      svg {
        rect {
          fill: #2b2b2b;
          //@include media("<1260px") {
            //  width: 20px;
          //}
        }
        path {
          fill: var(--color-primary);
        }
      }
    }

    &-span,
    img {
      margin: 0 0.05rem;
    }
  }
}

.time-modal {
  font-size: 1rem;
}

.full-screen-off {
  rect {
    fill: #2b2b2b;
  }
  path {
    fill: var(--color-primary);
  }
}

.vm-select-container {
  margin: auto 0 auto 0.5rem;

  .option-container {
    padding: 5px 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: auto;

    button {
      color: inherit;
      padding: 0;

      svg {
        width: 12px;
      }
    }
  }

  .vm-select {
    &__control {
      min-width: 110px;
      @include media("<desktop") {
        min-width: 80px;
      }
      min-height: 25px;
      background: #2b2b2b;
      border: none;
      box-shadow: none;

      .value-container-btn {
        flex: 1 1;
        text-align: left;
      }
    }

    &__loading-indicator {
      padding: 0;

      span {
        margin-left: 4px;
      }
    }

    &__single-value {
      font-size: 12px;
      color: white;
    }

    &__placeholder {
      font-size: 12px;
    }

    &__indicators {
      height: 25px;
      width: 25px;
    }

    &__indicator {
      padding: 8px 2px;
      cursor: pointer;
    }

    &__indicator-separator {
      margin-top: 6px !important;
      margin-bottom: 6px !important;
      margin-left: 0;
    }

    &__menu {
      z-index: 20000;
    }

    &__menu-list {
      background-color: white;
      color: #212529;
      border-radius: 4px;
      padding: 5px 0;
      min-width: 130px;
    }

    &__option {
      padding: 0;
      cursor: pointer;
      background-color: transparent;
      width: auto;

      &:hover,
      &:active,
      &:focus {
        color: #16181b;
      }

      &--is-disabled {
        pointer-events: none;
      }

      &--is-selected {
        color: var(--color-primary);

        &:hover {
          color: #b23800;
        }

        ~ button {
          color: var(--color-primary);

          &:hover {
            color: #b23800;
          }
        }
      }
    }
  }
}
